/* autoprefixer grid: autoplace */
@import-normalize;

.rv-xy-plot__axis__tick__text {
    fill: #7AB9DB
}

.rv-discrete-color-legend-item__title {
    color:white;
    font-weight: bold;
}


/* React-Calendar-Timeline overrides */
.rct-header-root {
    background-color: #2E4162 !important;
}

.rct-outer {
    background-color: rgba(122,185,219,0.1);
}

.rct-dateHeader {
    background-color: #6E8093 !important;
}

.rct-dateHeader-primary {
    background-color: initial !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background-color: transparent !important;
}

/* React-Grid-Layout overrides */
.react-grid-layout {
    margin: 10px 0;
    background-color: #54657f;
    min-height: 70px;
}

.react-grid-item {
    background-color: #34435a;
    border: 1px solid #7AB9DB;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*** General CSS ***/
.width-50 {
    width: 50%;
}

/*** React JSONSCHEMA FORM **/

.hidden-title>label {
    display: none;
}